import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import queryString from 'query-string';
import WelcomePage from './components/WelcomePage';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import Help from './components/Help';
import RefundAndCancellation from './components/RefundAndCancellation';
import SignupPage from './components/SignupPage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import AccountDetails from './components/AccountDetails'
import PaymentInfo from './components/PaymentInfo';
import UserContext from './context/UserContext';
import Users from './components/AdminDashboard/Users'
import UserDetails from './components/AdminDashboard/UserDetails';
import {getUserData} from './utils/actions/authActions'

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firebaseInstances } from './utils/firebaseHelper';

const { firestore: db } = firebaseInstances;


const UserDetailsWrapper = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUser(userDocSnapshot.data());
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [userId]);

  return <UserDetails user={user} />;
};


function App() {
  const [userData, setUserData] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const token = user.getIdToken();
        getUserData(user.uid)
          .then((userData) => {
            setUserData(userData);
            setAuthToken(token);
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            // Handle the error scenario
          });
      } else {
        // User is signed out
        const parsedUrl = queryString.parseUrl(window.location.href);
        const { email, token } = parsedUrl.query;
  
        if (token) {
          // Auto-login with token
          signInWithToken(email, token)
            .then((userData) => {
              setUserData(userData);
              setAuthToken(token);
            })
            .catch((error) => {
              console.error('Error during auto-login:', error);
              // Handle the error scenario
            });
        } else {
          setUserData(null);
          setAuthToken(null);
        }
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  // ...
  
  const signInWithToken = async (email, token) => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, token);
      const user = userCredential.user;
      const userData = await getUserData(user.uid);
      return userData;
    } catch (error) {
      console.error('Error during sign-in with token:', error);
      throw error;
    }
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, authToken, setAuthToken }}>
    <Router>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/Terms-and-Conditions" element={<TermsAndConditions />} />
        <Route path="/Contact-Us" element={<ContactUs />} />
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/help" element={<Help />} />
        <Route path="/Refund-and-Cancellation" element={<RefundAndCancellation />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/Account-Details" element={<AccountDetails />} />
        <Route path="/Payment-Info" element={<PaymentInfo />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:userId" element={<UserDetailsWrapper />} />
      </Routes>
    </Router>
    </UserContext.Provider>
  );
};

export default App;