import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { removeUserDataFromLocalStorage } from '../utils/firebaseHelper';

const LeftMenu = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    removeUserDataFromLocalStorage();
    navigate('/login');
  };

  if (!userData) {
    return null; // or return a loading placeholder
  }

  const isAdmin = userData.userType === 'Admin';
  
  return (
    <div className="left-menu">
      {userData && userData.userType && <div className="user-type">{userData.userType}</div>}
      <ul>
        <li
          className={location.pathname === '/dashboard' ? 'selected' : ''}
          onClick={() => navigate('/dashboard')}
          onTouchEnd={() => navigate('/dashboard')}
        >
          Dashboard
        </li>
        {isAdmin && (
          <li
            className={location.pathname === '/users' ? 'selected' : ''}
            onClick={() => navigate('/users')}
            onTouchEnd={() => navigate('/users')}
          >
            Users
          </li>
        )}
        <li
          className={location.pathname === '/account-details' ? 'selected' : ''}
          onClick={() => navigate('/account-details')}
          onTouchEnd={() => navigate('/account-details')}
        >
          Account Details
        </li>
        <li
          className={location.pathname === '/Payment-Info' ? 'selected' : ''}
          onClick={() => navigate('/Payment-Info')}
          onTouchEnd={() => navigate('/Payment-Info')}
        >
          Payment Info
        </li>
        <li
          onClick={handleLogout}
          onTouchEnd={handleLogout}
        >
          Logout
        </li>
      </ul>
    </div>
  );
};

export default LeftMenu;