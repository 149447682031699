import React, { useState } from 'react';
import './Help.css';
import logo from '../assets/Images/logo.png';

const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`faq-item ${isExpanded ? 'expanded' : ''}`}>
      <div className="question-container" onClick={() => setIsExpanded(!isExpanded)}>
        <h3 className="question">{question}</h3>
        <span className={`chevron ${isExpanded ? 'up' : 'down'}`}>
          {isExpanded ? '−' : '+'}
        </span>
      </div>
      <div className={`answer-container ${isExpanded ? 'show' : ''}`}>
        <p className="answer">{answer}</p>
      </div>
    </div>
  );
};

function Help() {
  const faqs = [
    {
      question: "How do I reset my password?",
      answer: "To reset your password, go to the login screen and click on 'Forgot Password'. Enter your email address and follow the instructions sent to your email."
    },
    {
      question: "How can I update my profile information?",
      answer: "Navigate to the Profile Settings from your dashboard. Click on 'Account Details' to modify your personal information, including name, email, and phone number."
    },
    {
      question: "What should I do if I'm having trouble logging in?",
      answer: "First, ensure you're using the correct email and password. If you've forgotten your password, use the 'Forgot Password' feature. If you're still having issues, try clearing your browser cache or using a different browser."
    },
    {
      question: "Can I use the website offline?",
      answer: "Our website requires an internet connection to access its features and ensure you have the most up-to-date information."
    },
    {
      question: "How do I contact customer support?",
      answer: "You can reach our customer support team by emailing support@ontour.co.in. Please provide as much detail as possible about your issue for faster assistance."
    },
    {
      question: "Is my personal information secure?",
      answer: "Yes, we take data security very seriously. All personal information is encrypted and stored securely. We never share your data with third parties without your explicit consent."
    },
    {
      question: "How do I delete my account?",
      answer: "To delete your account, in your app, go to Profile Settings and select 'Account Details'. At the bottom you'll find 'More', tap on it and you will see 'Request Account Deletion'. Please note that this process is irreversible after 60 days."
    }
  ];

  return (
    <div className="help-page">
      <header className="top-bar">
        <img src={logo} alt="On Tour Logo" className="logo" />
        <button className="sign-in-btn">Sign In</button>
      </header>
      
      <div className="container">
        <h1 className="title">Help Centre</h1>
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>

      <footer className="footer">
        <ul>
          <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
        </ul>
      </footer>
    </div>
  );
}

export default Help;