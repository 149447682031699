import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage, removeUserDataFromLocalStorage } from '../../utils/firebaseHelper';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getFirebaseApp } from '../../utils/firebaseHelper';
import './Dashboard.css';
import playstorelogo from '../../assets/Images/playstorelogo.png';
import appstorelogo from '../../assets/Images/appstorelogo.png';
import logo from '../../assets/Images/logo.png';
import UserContext from '../../context/UserContext';
import Layout from '../Layout';

export default function Dashboard() {
  const { userData, setUserData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userCounts, setUserCounts] = useState({ free: 0, pro: 0, elite: 0 });
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!userData) {
          navigate('/login');
          return;
        }

        const db = getFirestore(getFirebaseApp());
        const usersCollectionRef = collection(db, 'users');

        // Get total number of users
        const usersSnapshot = await getDocs(usersCollectionRef);
        setTotalUsers(usersSnapshot.size);

        // Get user counts by membership tier
        const artistQuery = query(usersCollectionRef, where('userType', '==', 'Artist'));
        const artistSnapshot = await getDocs(artistQuery);
        const crewQuery = query(usersCollectionRef, where('userType', '==', 'Crew'));
        const crewSnapshot = await getDocs(crewQuery);

        const freeUsers = artistSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Free').length +
                          crewSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Free').length;
        const proUsers = artistSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Pro').length +
                         crewSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Pro').length;
        const eliteUsers = artistSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Elite').length +
                           crewSnapshot.docs.filter((doc) => doc.data().membershipTier === 'Elite').length;

        setUserCounts({ free: freeUsers, pro: proUsers, elite: eliteUsers });

        // Get total income
        let totalIncome = 0;
        usersSnapshot.docs.forEach((doc) => {
          const paymentHistory = doc.data().paymentHistory || [];
          paymentHistory.forEach((payment) => {
            totalIncome += payment.amount;
          });
        });
        setTotalIncome(totalIncome);

        // Get total tours
        const toursCollectionRef = collection(db, 'Tours');
        const toursSnapshot = await getDocs(toursCollectionRef);
        setTotalTours(toursSnapshot.size);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [userData, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="dashboard">
        <div className="dashboard-header">
         
           
            <h1>On Tour Admin Dashboard</h1>
          </div>
          
      
        <div className="dashboard-content">
          <div className="analytics-cards">
            <div className="analytics-card">
              <h3>Total Users</h3>
              <p>{totalUsers}</p>
              <div className="user-counts">
                <div>
                  <span>Free</span>
                  <span>{userCounts.free}</span>
                </div>
                <div>
                  <span>Pro</span>
                  <span>{userCounts.pro}</span>
                </div>
                <div>
                  <span>Elite</span>
                  <span>{userCounts.elite}</span>
                </div>
              </div>
            </div>
            <div className="analytics-card">
              <h3>Total Income</h3>
              <p>₹{(totalIncome / 100).toFixed(2)}</p>
            </div>
            <div className="analytics-card">
              <h3>Total Tours</h3>
              <p>{totalTours}</p>
            </div>
          </div>
          {/* Add additional content or components as needed */}
        </div>
      </div>
    </Layout>
  );
}