import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import './UserDetails.css';

const UserDetails = ({ user }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('general');

  const handleBackClick = () => {
    navigate('/users'); // Navigate to the /users route
  };

  // Check if the user prop exists and has a value
  if (!user) {
    return <div>Loading...</div>; // Render a loading state or any other appropriate message
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderGeneralTab = () => (
    <div>
      <p>Full Name: {user.fullName || 'N/A'}</p>
      <p>Email: {user.email || 'N/A'}</p>
<p>Username: {user.userName || 'N/A'}</p>
<p>Phone Number: {user.phoneNumber || 'N/A'}</p>
      <p>User Type: {user.userType || 'N/A'}</p>
 <p>User Sub Type: {user.userSubType || 'N/A'}</p>
<p>Membership Tier: {user.membershipTier || 'N/A'}</p>
<p>Membership End Date: {user.membershipEndDate ? new Date(user.membershipEndDate).toLocaleDateString() : 'N/A'}</p>
      <p>Membership Start Date: {user.membershipStartDate ? new Date(user.membershipStartDate).toLocaleDateString() : 'N/A'}</p>
         
 <p>Sign Up Date: {user.signUpDate ? new Date(user.signUpDate).toLocaleDateString() : 'N/A'}</p>
   
    </div>
  );

  const renderIdVerificationTab = () => (
    <div>
      <p>ID Verification Status: {user.idVerificationStatus || 'N/A'}</p>
      <p>ID Verification Media:</p>
      <ul>
        <li>Front Photo: {user.idVerification?.frontPhotoUrl || 'N/A'}</li>
        <li>Back Photo: {user.idVerification?.backPhotoUrl || 'N/A'}</li>
      </ul>
      
    </div>
  );

  const renderPaymentHistoryTab = () => {
    // Calculate total amount paid by the user
    const totalAmount = user.paymentHistory.reduce((total, payment) => total + payment.amount, 0);
  
    return (
      <div>
        <p>Payment History:</p>
        <table>
          <thead>
            <tr>
              <th>Payment #</th>
              <th>Amount (₹)</th>
              <th>Membership Start Date</th>
              <th>Membership End Date</th>
              <th>Membership Type</th>
              <th>Payment Method</th>
              <th>Payment ID</th>
            </tr>
          </thead>
          <tbody>
            {user.paymentHistory.map((payment, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>₹{payment.amount / 100}</td>
                <td>{new Date(payment.membershipStartDate).toLocaleDateString()}</td>
                <td>{new Date(payment.membershipEndDate).toLocaleDateString()}</td>
                <td>{payment.membershipType}</td>
                <td>{payment.method}</td>
                <td>{payment.paymentId}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Total Amount Paid: ₹{totalAmount / 100}</p>
      </div>
    );
  };
  
  

 const renderPaymentInfoTab = () => (
<div>
<p>Payment Info:</p>
   <ul>
            <li>Account Number: {user.paymentInfo?.accountNumber || 'N/A'}</li>
            <li>Bank: {user.paymentInfo?.bank || 'N/A'}</li>
            <li>Card Last Four: {user.paymentInfo?.cardLastFour || 'N/A'}</li>
            <li>Card Network: {user.paymentInfo?.cardNetwork || 'N/A'}</li>
            <li>IFSC: {user.paymentInfo?.ifsc || 'N/A'}</li>
            <li>Payment Method: {user.paymentInfo?.method || 'N/A'}</li>
            <li>UPI ID: {user.paymentInfo?.upiId || 'N/A'}</li>
 <p>UPI ID: {user.upiId || 'N/A'}</p>
          </ul>
</div>
  );


  const renderSocialLinksTab = () => (
    <div>
     <ul>
            <li>Apple Music URL: {user.socialLinks.appleMusicUrl || 'N/A'}</li>
            <li>Facebook URL: {user.socialLinks.facebookUrl || 'N/A'}</li>
            <li>Instagram URL: {user.socialLinks.instagramUrl || 'N/A'}</li>
            <li>LinkedIn URL: {user.socialLinks.linkedInUrl || 'N/A'}</li>
            <li>Spotify URL: {user.socialLinks.spotifyUrl || 'N/A'}</li>
            <li>Twitter URL: {user.socialLinks.twitterUrl || 'N/A'}</li>
            <li>YouTube URL: {user.socialLinks.youtubeUrl || 'N/A'}</li>
          </ul>
    </div>
  );

  const renderOtherDetailsTab = () => (
    <div>
    <p>Profile Details:</p>

     <p>Bio: {user.bio || 'N/A'}</p>
<p>Profile Photo URL: <img src={user.profilePhotoUrl || 'N/A'} alt="Profile Photo" style={{ maxWidth: '200px' }} /></p>
          <p>Cover Photo URL: <img src={user.coverPhotoUrl || 'N/A'} alt="Cover Photo" style={{ maxWidth: '200px' }} /></p>
          <p>Birthday: {user.birthday ? new Date(user.birthday).toLocaleDateString() : 'N/A'}</p>
          <p>Gender: {user.gender || 'N/A'}</p>
          <p>City: {user.city || 'N/A'}</p>
          <p>State: {user.state || 'N/A'}</p>
          <p>Country: {user.country || 'N/A'}</p>
          <p>User ID: {user.userId || 'N/A'}</p>
 <p>Shows Count: {user.showsCount || 'N/A'}</p>
 <p>Tours Count: {user.toursCount || 'N/A'}</p>
          <p>Users Count: {user.usersCount || 'N/A'}</p>
    </div>
  );

  return (
    <Layout>
      <div className="user-details">
        <div className="main-content">
          <div className="header">
            <button className="back-button" onClick={handleBackClick}>
              <span role="img" aria-label="Back">
                &#8592; Back
              </span>
            </button>
            <h2>User Details</h2>
            <h2>Name: {user.fullName || 'N/A'}</h2>
          </div>
          <div className="tabs">
            <button className={activeTab === 'general' ? 'active' : ''} onClick={() => handleTabClick('general')}>General</button>
            <button className={activeTab === 'idVerification' ? 'active' : ''} onClick={() => handleTabClick('idVerification')}>ID Verification</button>
            <button className={activeTab === 'paymentHistory' ? 'active' : ''} onClick={() => handleTabClick('paymentHistory')}>Payment History</button>
            <button className={activeTab === 'paymentInfo' ? 'active' : ''} onClick={() => handleTabClick('paymentInfo')}>Payment Info</button>
            <button className={activeTab === 'socialLinks' ? 'active' : ''} onClick={() => handleTabClick('socialLinks')}>Social Links</button>
            <button className={activeTab === 'otherDetails' ? 'active' : ''} onClick={() => handleTabClick('otherDetails')}>Other Details</button>
          </div>
          <div className="tab-content">
            {activeTab === 'general' && renderGeneralTab()}
            {activeTab === 'idVerification' && renderIdVerificationTab()}
            {activeTab === 'paymentHistory' && renderPaymentHistoryTab()}
            {activeTab === 'paymentInfo' && renderPaymentInfoTab()}
            {activeTab === 'socialLinks' && renderSocialLinksTab()}
            {activeTab === 'otherDetails' && renderOtherDetailsTab()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserDetails;
