import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { firebaseInstances } from '../../utils/firebaseHelper';
import { useNavigate } from 'react-router-dom';
import UserDetails from './UserDetails';
import Layout from '../Layout';
import './Users.css';

const { firestore: db } = firebaseInstances;

const Users = () => {
  const [users, setUsers] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersQuery = query(collection(db, 'users'));
        const querySnapshot = await getDocs(usersQuery);
        const usersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleUpdateUser = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, updatedData[userId] || {});
      console.log('User updated successfully');
      setUpdatedData({});
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleUpdateFieldChange = (userId, field, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [userId]: {
        ...(prevData[userId] || {}),
        [field]: value,
      },
    }));
  };

  return (
    <Layout>
      <div className="user">
        <div className="main-content">
        <div className="userlistheader">
          <h1>User List</h1>
          </div>
          <input
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          {selectedUser ? (
            <UserDetails user={selectedUser} />
          ) : (
            <UserList
              users={filteredUsers}
              updatedData={updatedData}
              handleUpdateFieldChange={handleUpdateFieldChange}
              handleUpdateUser={handleUpdateUser}
              navigate={navigate}
              setSelectedUser={setSelectedUser}
              currentPage={currentPage}
              usersPerPage={usersPerPage}
              handlePageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

const UserList = ({
  users,
  updatedData,
  handleUpdateFieldChange,
  handleUpdateUser,
  navigate,
  setSelectedUser,
  currentPage,
  usersPerPage,
  handlePageChange,
}) => {
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(
        <li key={i} className={currentPage === i ? 'active' : ''}>
          <button onClick={() => handlePageChange(i)}>{i}</button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>User Type</th>
            <th>Membership Tier</th>
            <th>ID Verification Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.userType}</td>
              <td>
                <select
                  value={updatedData[user.id]?.membershipTier || user.membershipTier}
                  onChange={(e) => handleUpdateFieldChange(user.id, 'membershipTier', e.target.value)}
                >
                  <option value="Free">Free</option>
                  <option value="Pro">Pro</option>
                  <option value="Elite">Elite</option>
                </select>
              </td>
              <td>
                <select
                  value={updatedData[user.id]?.idVerificationStatus || user.idVerificationStatus}
                  onChange={(e) => handleUpdateFieldChange(user.id, 'idVerificationStatus', e.target.value)}
                >
                  <option value="Not Verified">Not Verified</option>
                  <option value="Pending">Pending</option>
                  <option value="Verified">Verified</option>
                </select>
              </td>
              <td>
              <button
  onClick={() => handleUpdateUser(user.id)}
  style={{
    backgroundColor: '#3f51b5',
    color: '#fff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
  }}
>
  Update
</button>
<button
  onClick={() => navigate(`/users/${user.id}`)}
  style={{
    backgroundColor: '#E2384D',
    color: '#fff',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '8px',
  }}
>
  Details
</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ul className="pagination">{renderPageNumbers()}</ul>
    </>
  );
};

export default Users;